import { GoogleMap, useLoadScript } from '@react-google-maps/api';
import React, { useMemo } from 'react';

export default function Home(props) {
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_API_KEY,
  });

  if (!isLoaded) return <div>Loading...</div>;
  return <Map {...props} />;
}

function Map(props) {
  const center = useMemo(
    () => ({ lat: props.location.lat, lng: props.location.lng }),
    [props.location.lat, props.location.lng],
  );

  return (
    <div className='container'>
      <div className='row'>
        <div className='col-12'>
          <div className='map'>
            <div className='google-map'>
              <GoogleMap
                zoom={props.zoomLevel}
                center={center}
                mapContainerClassName='map-container'
                mapContainerStyle={{ height: '60vh', width: '100%' }}
              >
                {/* <Marker position={center} /> */}
              </GoogleMap>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
