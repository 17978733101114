import React from 'react';
import { Button, Form } from 'react-bootstrap';
import styles from './ContactBlockStyles';
import { sendDetails } from '../../api/apiCalls';

const ContactBlock = () => {
  const [email, setEmail] = React.useState('');
  const [validated, setValidated] = React.useState(false);

  function handleClick(e) {
    e.preventDefault();
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.preventDefault();
      e.stopPropagation();
      setValidated(true);
    } else {
      sendDetails(e, { email: email });
      setEmail('');
      setValidated(false);
    }
  }
  return (
    <Form noValidate validated={validated} onSubmit={handleClick}>
      <div className='pt-5 pb-5' style={styles.SectionStyle}>
        <div className='container pt-4 pb-4'>
          <div className='row align-items-center'>
            <div className='col-lg-3 col-md-6 mb-4 mb-md-0'>
              <div className='row'>
                <div className='col' style={styles.HeaderTextStyle}>
                  Want to know more?
                </div>
              </div>
              <div className='row'>
                <div className='col' style={styles.SubTextStyle}>
                  Give us your email and we’ll be in touch to arrange a
                  consultation
                </div>
              </div>
            </div>

            <div className='col-lg-6 col-md-6 mb-4 mb-md-0'>
              <Form.Control
                style={styles.TextInputStyle}
                placeholder='Enter your email address here'
                type='email'
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
              <Form.Control.Feedback style={{ color: 'white' }} type='invalid'>
                Please enter a valid email address.
              </Form.Control.Feedback>
            </div>

            <div className='col-lg-3 col-md-12 mb-4 mb-md-0'>
              <Button type='submit' style={styles.ButtonStyle}>
                Send
                <i
                  className='bi bi-three-dots-vertical'
                  style={styles.ThreeDots}
                ></i>
                <i style={styles.CheckMark} className='bi bi-check-lg'></i>
              </Button>
            </div>
          </div>
        </div>
      </div>
    </Form>
  );
};

export default ContactBlock;
