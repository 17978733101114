export const FAQSportsMassage = [
  {
    position: 1,
    question: 'Can I get a sports massage if I don’t do sport?',
    content: [
      'Absolutely! Even if you don’t do any form of sport or exercise, our everyday movements still put stresses on our body, which can, over time, lead to long-term issues. People who drive a lot as part of their job and many office workers who spend long hours sitting at a desk suffer from pain and stiffness in their neck, shoulders, and lower back, which can become quite a serious problem. Regular sports massage will help ease the strain put on these areas of your body, reducing the risk of serious problems arising.',
      'Anyone working in a physically demanding job lifting and carrying heavy objects can also benefit from regular sports massage to help counteract the stress put on their knee joints, lower back and shoulders.',
      'Sports massage will help your muscles, as well as your mind, to become more relaxed, allowing them to move more freely, promoting flexibility and reducing risk of injury.',
    ],
  },

  {
    position: 2,
    question: 'Will sports massage help my injury?',
    content: [
      'Yes. Sports massage is proven to reduce recovery time, by shortening the time it takes for injuries to heal.  Sports Massage reduces the swelling and oedema associated with soft tissue injuries. After a serious injury, Sports Massage helps form strong, pliable scar tissue instead of the usual random stiff scar tissue, so that range of motion and tissue extensibility are maintained.',
      'A short list of benefits include:',
    ],
    bulletPoints: [
      'Shortens the time it takes for an injury to heal',
      'Helps to reduce swelling and oedema.',
      'Helps to form soft, pliable scar tissue.',
      'Maintains or increases the range of motion.',
      'Eliminates splinting in associated muscle tissue.',
      'Locates and deactivates “trigger points” that form because of the original trauma.',
      'Help get the athlete back into training sooner with less chance of re-injury.',
    ],
  },

  {
    position: 3,
    question: 'How often should I get a sports massage?',
    content: [
      'If you are an athlete/participate in sport, factors such as training volume and intensity need to be considered, also, whether you have chronic pain or acute injury, etc. If you don’t participate in sport, then occupational factors may come into play, for example, do you sit at a desk all day and have a tight neck and shoulders? Or do you carry out heavy manual work which makes you tight and prone to injury?',
      'For some athletes, a weekly massage gives huge results reducing the effects of hard training. For others with stress or occupational aches and pains, having a sports massage regularly once a month as a long-lasting positive impact.',
    ],
  },
  {
    position: 4,
    question: 'Will one visit fix my problems?',
    content: [
      'Sometimes yes. If you have a tight, painful neck and shoulder complex, for example, you can expect to come away from a Sports Massage experiencing a lot of relief, at least for a while. But it probably took you months or years to get that way. It’s not reasonable to expect permanent relief from an hour session of Sports Massage. The effects of Sports Massage are very much like the effects of athletic training. Your first bike ride won’t turn you into Bradley Wiggins, but a consistent training program will give you massive improvements.',
    ],
  },

  {
    position: 5,
    question: 'Can I still get a sports massage while pregnant?',
    content: [
      'During pregnancy, hormonal changes cause ligaments and joints to soften leading to additional strain on the skeletal system. This often causes aches and pains most commonly in the hips, buttocks, and lower back. The increasing weight of the baby can pull on the lumbar spine causing postural imbalances. These changes can be difficult to prepare for, but massage during pregnancy can help to reduce these discomforts and promote relaxation and a sense of well-being.',
      'Massage therapy during pregnancy can help: -',
    ],
    bulletPoints: [
      'Ease muscle and joint strain',
      'Reduce stress and anxiety',
      'Promote relaxation and sleep',
      'Reduce oedema and blood pressure',
      'Improve flexibility and energy levels',
      'Support your physiological and emotional needs',
    ],

    boldText: [
      'PLEASE NOTE: IF YOU ARE IN YOUR FIRST TRIMESTER OF PREGNANCY, PLEASE CONTACT YOUR GP BEFORE HAVING A MASSAGE. IF YOU ARE CONSIDERED HIGH RISK, PLEASE ALSO TAKE THE ADVICE OF YOUR GP AT ANY STAGE OF PREGNANCY.',
    ],
  },

  {
    position: 6,
    question: 'Will it be painful?',
    content: [
      'Sports massage uses a lot more pressure to release tightness etc than other types of massage. However, we will always work within your individual capacity for pressure.  Sometimes individuals develop knots or trigger points that require stronger bodywork and may cause discomfort. If this is the case, we will communicate with you about pain levels, working together to make the session as productive as possible, and as pain-free as can be. For people with conditions such as Fibromyalgia and chronic fatigue and have a very low tolerance for pain, the massage is more of a flush out to relieve pain, rather than a very deep massage.',
    ],
  },
  {
    position: 7,
    question: 'Are there times I shouldn’t be treated?',
    content: [
      'Yes, there are conditions when you should not be massaged. The most common condition among athletes is that of an acute injury or inflammation. Usually, the first 72 hours after an injury is the acute stage. After the swelling and pain begin to diminish, massage is recommended. Other contraindications are fever, vascular conditions, severe heart disease, contagious skin conditions, inflammation, abrasions, cuts, hematomas, cancer, neuritis, recent surgery, infectious diseases, diabetes with vascular dysfunction, fractures, and acute injury. There are too many possible conditions to list, so it is your responsibility to alert us to any medical condition or injury before a massage begins. Some conditions are localised so massage may take place, except in the affected area. Always consult with your doctor if unsure.',
    ],
  },
];

const items = {
  FAQSportsMassage: FAQSportsMassage,
};

export default items;
