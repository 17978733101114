import React from 'react';
import { Card } from 'react-bootstrap';
import styles2 from '../offersection/OfferSectionStyle';
import ContentCardStyles from './ContentCardStyles';

const ContentCardTextsMobile = (props) => {
  return (
    <Card
      style={{
        width: '90%',
        marginLeft: 'auto',
        marginRight: 'auto',
        borderRadius: '8px',
        borderBottom: props.last ? '' : 'none',
        borderTop: props.first ? '' : 'none',
        marginBottom: props.last ? '15%' : 'none',
        marginTop: props.first ? '15%' : 'none',
      }}
    >
      <Card.Body>
        <>
          <div className='container'>
            {props.mainTitle && (
              <div style={styles2.OffersectionTitle} className='row'>
                <div className='col-auto'>
                  <h1 style={ContentCardStyles.Title}>{props.mainTitle}</h1>
                </div>
                <div className='col-auto'></div>
              </div>
            )}
            <div className='row' style={ContentCardStyles.NewSection}>
              <div className='col-md'>
                {props.leftContent.map((item, key) => {
                  return (
                    <React.Fragment key={key}>
                      {item.title && (
                        <>
                          <h2>{item.title}</h2>
                        </>
                      )}
                      {item.subTitle && (
                        <>
                          <h2>{item.subTitle}</h2>
                          <br />
                        </>
                      )}

                      {item.content && (
                        <p style={{ fontSize: '1.2em' }}>{item.content}</p>
                      )}
                      {item.list && (
                        <ul>
                          {item.list.map((curr, key) => (
                            <li key={key}>
                              <p style={{ fontSize: '1.2em' }}>{curr}</p>
                            </li>
                          ))}
                        </ul>
                      )}
                      <br />
                    </React.Fragment>
                  );
                })}
              </div>
              <div className='col-md'>
                {!!!props.rightImageContent && (
                  <>
                    <h2 style={ContentCardStyles.subTitle}>
                      {props.rightTitle}
                    </h2>
                    <ul>
                      {props.rightContent.map((item, key) => {
                        if (props.rightIsImage) {
                          return (
                            <React.Fragment key={key}>{item}</React.Fragment>
                          );
                        } else {
                          return (
                            <React.Fragment key={key}>
                              <li style={{ fontSize: '1.2em' }}>{item}</li>
                            </React.Fragment>
                          );
                        }
                      })}
                    </ul>
                  </>
                )}

                {!!props.rightImageContent && (
                  <>
                    <hr style={ContentCardStyles.HorizontalRuleStyle2} />
                    {props.rightImageContent.map((item, key) => {
                      return (
                        <div
                          className='row '
                          key={key}
                          style={ContentCardStyles.NewSection2}
                        >
                          <div className='col'>
                            <img
                              src={item.image}
                              className='d-inline-block align-top'
                              alt='React Bootstrap logo'
                            />
                          </div>

                          <div
                            className='col'
                            style={ContentCardStyles.PriceTextAlign}
                          >
                            <div>
                              <b>{item.session}</b>
                            </div>
                          </div>

                          <div
                            className='col'
                            style={ContentCardStyles.PriceTextAlign}
                          >
                            <div>
                              <b>{item.price}</b>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                    <hr style={ContentCardStyles.HorizontalRuleStyle2} />
                  </>
                )}
              </div>
            </div>
          </div>
        </>
      </Card.Body>
    </Card>
  );
};

export default ContentCardTextsMobile;
