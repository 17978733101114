import { baseURL, handleBookingURL } from '../var';
import axiosInstance from './axiosInstance';

export const sendDetails = async (e, details) => {
  let jsonDetail = JSON.stringify(details);

  try {
    await axiosInstance.post(`${baseURL}`, jsonDetail);
  } catch (e) {
    console.error(e, 'error message');
  }
};

export const handleBookingFunction = async (e, details) => {
  let jsonDetail = JSON.stringify(details);

  try {
    await axiosInstance.post(`${handleBookingURL}`, jsonDetail);
  } catch (e) {
    console.error(e, 'error message');
  }
};
