import React from 'react';
import Media from 'react-media';
import ContentCardStyles from '../contentCard/ContentCardStyles';
import Map from '../Map';
import AddressDetailMobile from './AddressDetailMobile';

const AddressDetail = (props) => {
  return (
    <>
      <Media
        query='(min-width: 900px)'
        render={() => (
          <>
            <div className='container'>
              <div
                className='row'
                style={{ marginBottom: '30px', marginTop: '10px' }}
              >
                <div className='col-auto'>
                  <h1 style={ContentCardStyles.Title}>{props.mainTitle}</h1>
                </div>
                <div className='col-auto'></div>
              </div>

              <div className='row justify-content-between'>
                <div
                  className='col'
                  style={{ marginRight: '30px', marginBottom: '10px' }}
                >
                  <h2>{props.subTitle1}</h2>
                </div>
                <div
                  className='col'
                  style={{ marginRight: '10px', marginBottom: '10px' }}
                >
                  <h2>{props.subTitle2}</h2>
                </div>
                <div
                  className='col'
                  style={{ marginRight: '10px', marginBottom: '10px' }}
                >
                  <h2>{props.subTitle3}</h2>
                </div>
              </div>

              <div className='row justify-content-around'>
                <div className='col' style={{ marginRight: '30px' }}>
                  {props.content1}
                </div>
                <div className='col' style={{ marginRight: '10px' }}>
                  {props.content2}
                </div>
                <div className='col' style={{ marginRight: '10px' }}>
                  {props.content3}
                </div>
              </div>
            </div>
            <div style={{ marginTop: '3em', marginBottom: '5em' }}>
              <Map location={props.location} zoomLevel={props.zoomLevel} />
            </div>
          </>
        )}
      />
      <Media
        query='(max-width: 899px)'
        render={() => <AddressDetailMobile {...props} />}
      />
    </>
  );
};

export default AddressDetail;
