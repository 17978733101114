import acupuncture from '../images/backgroundImages/offersection/acupuncture.png';
import hotstoneMassage from '../images/backgroundImages/offersection/hotstone-massage.png';
import therapy from '../images/backgroundImages/offersection/therapy.png';

export const PromotionalCardItems = [
  {
    position: 1,
    imgSrc: therapy,
    imgAlt: 'therapist giving a message',
    header: 'Sports therapies',
    body: 'A deep tissue massage to ease pain and correct problems caused by repetitive and strenuous activity',
    subtext: 'everyone',
    pain: true,
    rehab: true,
    wellbeing: true,
    seeMore: 'See more about our sports therapies',
    linkTo: '/sports-massage',
  },

  {
    position: 2,
    imgSrc: acupuncture,
    imgAlt: 'Acupuncture needle going through the skin',
    header: 'Acupuncture',
    body: 'Dry needling for pain relief and injury',
    subtext: 'almost everyone',
    pain: true,
    rehab: true,
    wellbeing: true,
    seeMore: 'See more about our acupuncture options',
    linkTo: '/acupuncture',
  },

  {
    position: 3,
    imgSrc: hotstoneMassage,
    imgAlt: 'therpist giving a message',
    header: 'Hot stone massage',
    body: 'A deeply relaxing treatment using oils and hot stones',
    subtext: 'everyone',
    rehab: true,
    wellbeing: true,
    seeMore: 'See more about hot stone massage',
    linkTo: '/hotstone-massage',
  },
];

const items = {
  PromotionalCardItems: PromotionalCardItems,
};

export default items;
