import React from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import "./App.css";
import ContactBlock from "./component/contactBlock/ContactBlock";
import Footer from "./component/footer/Footer";
import Navbar from "./component/navbar/Navbar";
import SubNavbar from "./component/navbar/SubNavbar";
import ScrollToTop from "./component/ScrollToTop";
import AboutUs from "./pages/AboutUs";
import Acupuncture from "./pages/Acupuncture";
import Home from "./pages/Home";
import HotStoneMassage from "./pages/HotStoneMassage";
import SportsMassage from "./pages/SportsMassage";
function App() {
  const [dropdownVisible, setDropdownVisible] = React.useState(false);

  function toggleDropdown() {
    setDropdownVisible(!dropdownVisible);
  }

  return (
    <div className="App">
      <BrowserRouter>
        <ScrollToTop />
        <Navbar toggleSubNavbar={toggleDropdown} />
        <SubNavbar dropdownVisible={dropdownVisible} />

        <Switch>
          <Route exact path="/">
            <Home />
          </Route>
          <Route exact path="/acupuncture">
            <Acupuncture />
          </Route>
          <Route path="/about">
            <AboutUs />
          </Route>
          <Route path="/hotstone-massage">
            <HotStoneMassage />
          </Route>
          <Route path="/sports-massage">
            <SportsMassage />
          </Route>
        </Switch>
        <ContactBlock />
        <Footer />
      </BrowserRouter>
    </div>
  );
}

export default App;
