const accordionBorderStyle = {
  border: 'none',
  fontSize: '18px',
};

const styles = {
  accordionBorderStyle: accordionBorderStyle,
};

export default styles;
