import React from "react";
import Media from "react-media";
import OffersectionImageCard from "./offersectionContent/OffersectionImageCard";
import OffersectionMobile from "./offersectionContent/OffersectionMobile";
import OffersectionTextCard from "./offersectionContent/OffersectionTextCard";

const OfferSection = (props) => {
  return (
    <>
      <Media
        query="(min-width: 900px)"
        render={() => (
          <div className="container">
            <div className="row justify-content-around">
              <div className="col-6">
                <OffersectionImageCard {...props} />
              </div>
              <div className="col-6">
                <OffersectionTextCard {...props} />
              </div>
            </div>
          </div>
        )}
      />
      <Media
        query="(max-width: 899px)"
        render={() => <OffersectionMobile {...props} />}
      />
    </>
  );
};

export default OfferSection;
