import React from 'react';
import { Accordion } from 'react-bootstrap';
import ContentCardStyles from '../contentCard/ContentCardStyles';
import styles2 from '../offersection/OfferSectionStyle';
import AccordionStyle from './AccordionStyles';

const AccordionCard = (props) => {
  return (
    <div className='container'>
      <div style={styles2.OffersectionTitle} className='row'>
        <div className='col-auto'>
          <h1 style={ContentCardStyles.Title}>{props.title}</h1>
        </div>
        <div className='col-auto'></div>
      </div>

      <div className='row'>
        <div className='col-12'>
          <Accordion>
            {props.content.map((item, key) => {
              return (
                <Accordion.Item
                  eventKey={key}
                  style={AccordionStyle.accordionBorderStyle}
                  key={key}
                >
                  <hr style={ContentCardStyles.HorizontalRuleStyle2} />
                  <Accordion.Header>
                    <div style={{ fontSize: '1.2em' }}>{item?.question}</div>
                  </Accordion.Header>
                  <Accordion.Body>
                    {item.content?.map((item, key) => {
                      return (
                        <React.Fragment key={key}>
                          <>{item}</>
                          <br />
                        </React.Fragment>
                      );
                    })}

                    <ul>
                      {item?.bulletPoints?.map((item, key) => {
                        return (
                          <React.Fragment key={key}>
                            <li key={key}>{item}</li>
                          </React.Fragment>
                        );
                      })}
                    </ul>

                    {item.boldText?.map((item, key) => {
                      return (
                        <React.Fragment key={key}>
                          <b>{item}</b>
                          <br />
                        </React.Fragment>
                      );
                    })}
                  </Accordion.Body>
                </Accordion.Item>
              );
            })}
          </Accordion>
          <hr style={ContentCardStyles.HorizontalRuleStyle2} />
        </div>
      </div>
    </div>
  );
};

export default AccordionCard;
