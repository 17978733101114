import React from "react";
import FAQContent from "../assets/files/FAQAcupuncture";
import homeImage1 from "../assets/images/backgroundImages/large/Acupuncture.png";
import homeImage2 from "../assets/images/backgroundImages/small/Acupuncture.png";
import timeImage from "../assets/images/SixtyMinsIcon.png";
import AccordionCard from "../component/accordion/Accordion";
import BackgroundImage from "../component/BackgroundImage";
import ContentCard from "../component/contentCard/ContentCard";
import PageDivider from "../component/PageDivider";
import Testimony from "../component/testimony/Testimony";

const Acupuncture = (props) => {
  let coverText = "Acupuncture";
  let mainTitle = "What is acupuncture?";

  let testimonyText =
    "My lower back has been in trouble for some time, unable to walk far without excruciating pain. Felt a difference immediately after first treatment.";
  let testimonyName = "Avril Henn";

  let rightTitle = "Acupuncture can help with:";
  let leftContent = [
    {
      content:
        "Acupuncture, also known as dry needling, is one of the many techniques used within physiotherapy for the management of pain, soft tissue injuries and as a means of enhancing the body’s own healing chemicals to aid recovery and enhance rehabilitation.",
    },
    {
      content:
        "Acupuncture enhances pain modulation via stimulation of the brain and spinal cord to produce natural pain-relieving chemicals, such as endorphins and melatonin.  These chemicals assist the body’s healing process and can be used in conjunction with other soft tissue techniques.",
    },
  ];
  let rightContent = [
    "Joint pain such as knees, shoulders, back, hips and elbows",
    "Tightness/knots in larger muscles",
  ];

  //treatment

  let mainTitle2 = "Our treatments";
  let leftContent2 = [
    {
      title: "Acupuncture session",
      content:
        "The acupuncture points used will be determined after a thorough consultation. We may use traditional acupuncture points only, trigger points or a combination of both.",
    },
    {
      content:
        "Several needles may be used at each treatment, and these are left in position for about 20-30 minutes before being removed.",
    },
  ];
  let session = "1 hour full body";
  let price = "£60";

  let rightImageContent = [
    {
      image: timeImage,
      session,
      price,
    },
  ];

  return (
    <div>
      <BackgroundImage
        image1={homeImage1}
        image2={homeImage2}
        coverText={coverText}
      />
      <PageDivider />
      <ContentCard
        mainTitle={mainTitle}
        leftContent={leftContent}
        rightTitle={rightTitle}
        rightContent={rightContent}
      />

      <ContentCard
        mainTitle={mainTitle2}
        leftContent={leftContent2}
        rightImageContent={rightImageContent}
        mobileRes
        first
        last
      />

      <AccordionCard title={"FAQs"} content={FAQContent.FAQAcupuncture} />
      <Testimony testimonyText={testimonyText} testimonyName={testimonyName} />
    </div>
  );
};

export default Acupuncture;
