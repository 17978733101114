import React from 'react';
import { Card } from 'react-bootstrap';
import ContentCardStyles from '../contentCard/ContentCardStyles';
import Map from '../Map';

const AddressDetailMobile = (props) => {
  return (
    <Card
      style={{
        width: '90%',
        marginLeft: 'auto',
        marginRight: 'auto',
        borderRadius: '8px',
        marginBottom: '5em',
      }}
    >
      <Card.Body>
        <>
          <div className='container'>
            <div
              className='row'
              style={{ marginBottom: '30px', marginTop: '10px' }}
            >
              <div className='col-auto'>
                <h1 style={ContentCardStyles.Title}>{props.mainTitle}</h1>
              </div>
              <div className='col-auto'></div>
            </div>

            {/* <div className='row justify-content-between'>
              <div
                className='col-12'
                style={{ marginRight: '30px', marginBottom: '10px' }}
              >
                <h2>{props.subTitle1}</h2>
              </div>
              <div
                className='col'
                style={{ marginRight: '10px', marginBottom: '10px' }}
              >
                <h2>{props.subTitle2}</h2>
              </div>
              <div
                className='col'
                style={{ marginRight: '10px', marginBottom: '10px' }}
              >
                <h2>{props.subTitle3}</h2>
              </div>
            </div> */}

            <div
              className='row justify-content-start'
              style={{ marginBottom: '2em' }}
            >
              <div
                className='col-12'
                style={{ marginRight: '30px', marginBottom: '10px' }}
              >
                <h2>{props.subTitle1}</h2>
              </div>
              <div className='col-12' style={{ marginRight: '30px' }}>
                {props.content1}
              </div>
            </div>

            <div
              className='row justify-content-start'
              style={{ marginBottom: '2em' }}
            >
              <div
                className='col-12'
                style={{ marginRight: '30px', marginBottom: '10px' }}
              >
                <h2>{props.subTitle3}</h2>
              </div>
              <div className='col-6' style={{ marginRight: '30px' }}>
                {props.content3}
              </div>
            </div>

            <div className='row justify-content-start'>
              <div
                className='col-12'
                style={{ marginRight: '30px', marginBottom: '10px' }}
              >
                <h2>{props.subTitle2}</h2>
              </div>
              <div className='col-6' style={{ marginRight: '30px' }}>
                {props.content2}
              </div>
            </div>

            {/* <div className='row justify-content-around'>
              <div className='col' style={{ marginRight: '30px' }}>
                {props.content1}
              </div>
              <div className='col' style={{ marginRight: '10px' }}>
                {props.content2}
              </div>
              <div className='col' style={{ marginRight: '10px' }}>
                {props.content3}
              </div>
            </div> */}
          </div>
          <div style={{ marginTop: '3em', marginBottom: '2em' }}>
            <Map location={props.location} zoomLevel={props.zoomLevel} />
          </div>
        </>
      </Card.Body>
    </Card>
  );
};

export default AddressDetailMobile;
