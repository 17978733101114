import moment from "moment";
import React from "react";
import { Button, CloseButton, Col, Form, Modal } from "react-bootstrap";
import Colors from "../Colors";

const FullPageModal = (props) => {
  const [validated, setValidated] = React.useState(false);
  const [name, setName] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [telNo, setTelNo] = React.useState("");
  const [date, setDate] = React.useState("");
  const [time, setTime] = React.useState("");
  const [treatmentType, setTreatmentType] = React.useState("");

  const handleFormClick = (e) => {
    e.preventDefault();
    const form = e.currentTarget;
    console.log(form.checkValidity());
    if (form.checkValidity() === false) {
      e.preventDefault();
      e.stopPropagation();
      setValidated(true);
    } else {
      props.functionCall(e, {
        name,
        email,
        phone: telNo,
        date: moment(date).format("MMMM Do YYYY"),
        time,
        treatmentType,
      });
      setName("");
      setEmail("");
      setTelNo("");
      setDate("");
      setTime("");
      setTreatmentType("");
      setValidated(false);
      props.onHide();
    }
  };
  return (
    <React.Fragment>
      <Modal
        show={props.show}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        onHide={props.onHide}
        fullscreen
        style={{ color: "white", fontSize: "2.3vh" }}
      >
        <Modal.Header
          // closeButton
          style={{
            background: Colors.woodViewSecondary,
            color: "white",
            border: "none",
            paddingRight: "25px",
            paddingTop: "25px",
          }}
        >
          <CloseButton variant="white" onClick={props.onHide} />
        </Modal.Header>
        <Modal.Body
          style={{ background: Colors.woodViewSecondary, color: "white" }}
        >
          <Form validated={validated} onSubmit={handleFormClick}>
            <div className="row justify-content-center">
              <div className="col-auto">
                <h2 style={{ fontWeight: "700", fontSize: "2.4vh" }}>
                  TO BOOK A TREATMENT
                </h2>
              </div>
            </div>

            <div className="row justify-content-center mt-4">
              <div className="col-10 col-md-6">
                <div
                  className="col-auto"
                  style={{ fontSize: "2.2vh", textAlign: "center" }}
                >
                  Send the details below and we’ll give you a ring back to
                  confirm or call us on <b>07887 553886</b>
                </div>
              </div>
            </div>

            <div className="row justify-content-center mt-4">
              <div className="col-10 col-md-6">
                <Form.Group as={Col} controlId="formGridName1">
                  <Form.Label>Name</Form.Label>
                  <Form.Control
                    style={{ height: "6vh" }}
                    type="text"
                    placeholder=""
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    required
                  />
                </Form.Group>
              </div>
            </div>

            <div className="row justify-content-center mt-4">
              <div className="col-10 col-md-6">
                <Form.Group as={Col} controlId="formGridEmail">
                  <Form.Label>Email address</Form.Label>
                  <Form.Control
                    style={{ height: "6vh" }}
                    type="email"
                    placeholder=""
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                  />
                </Form.Group>
              </div>
            </div>

            <div className="row justify-content-center mt-4">
              <div className="col-10 col-md-6">
                <Form.Group as={Col} controlId="formGridEmail">
                  <Form.Label>Treatment Type</Form.Label>

                  <Form.Select
                    aria-label="Default select example"
                    style={{ height: "6vh" }}
                    onChange={(e) => setTreatmentType(e.target.value)}
                    value={treatmentType}
                    required
                  >
                    <option value="">Open this select menu</option>
                    <option value="Sports Massage">Sports Massage</option>
                    <option value="Acupuncture">Acupuncture</option>
                    <option value="Hot Stone Massage">Hot Stone Massage</option>
                  </Form.Select>
                </Form.Group>
              </div>
            </div>

            <div className="row justify-content-center mt-4">
              <div className="col-10 col-md-6">
                <Form.Group as={Col} controlId="formGridName">
                  <Form.Label>Telephone number</Form.Label>
                  <Form.Control
                    style={{ height: "6vh" }}
                    type="tel"
                    placeholder=""
                    value={telNo}
                    onChange={(e) => setTelNo(e.target.value)}
                    required
                  />
                </Form.Group>
              </div>
            </div>

            <div className="row justify-content-center mt-4 mb-5">
              <div className="col-5 col-md-3">
                <Form.Group as={Col} controlId="formGridName3">
                  <Form.Label>Preferred date</Form.Label>
                  <Form.Control
                    style={{ height: "6vh" }}
                    type="date"
                    placeholder="DD/MM/YYYY"
                    value={date}
                    onChange={(e) => setDate(e.target.value)}
                    required
                  />
                </Form.Group>
              </div>
              <div className="col-5 col-md-3">
                <Form.Group as={Col} controlId="formGridName4">
                  <Form.Label>Preferred time</Form.Label>
                  <Form.Control
                    style={{ height: "6vh" }}
                    type="time"
                    placeholder="Select"
                    value={time}
                    onChange={(e) => setTime(e.target.value)}
                    required
                  />
                </Form.Group>
              </div>
            </div>

            <div className="row justify-content-center mt-3 mb-4">
              <div className="col-10 col-md-6">
                <hr style={{ color: "white", height: "3px", width: "100%" }} />
              </div>
            </div>

            <div className="row justify-content-center">
              <div className="col-auto">
                <Button
                  style={{
                    fontSize: "1.5em",
                    paddingRight: "30px",
                    paddingLeft: "30px",
                    color: Colors.woodViewGrey,
                    backgroundColor: Colors.woodViewPrimary,
                    border: "none",
                  }}
                  type="submit"
                >
                  Send
                </Button>
              </div>
            </div>
          </Form>
        </Modal.Body>
      </Modal>
    </React.Fragment>
  );
};

export default FullPageModal;
