import React, { useState } from "react";
import {
  CloseButton,
  Container,
  Form,
  Nav,
  Navbar,
  Offcanvas,
} from "react-bootstrap";
import Media from "react-media";
import { Link } from "react-router-dom";
import Colors from "../../Colors";
import "./Navbar.css";
import styles from "./NavbarStyles";

const TopNavbar = (props) => {
  const [textColor, setTextColor] = useState(styles.navLink);
  const [isBlack, setIsBlack] = useState(true);
  const [hideOffCanvas, setOffCanvasHide] = useState(false);
  let colour = isBlack ? Colors.woodViewSecondary : Colors.headerTextColor;

  const handledropDownClick = (e) => {
    props.toggleSubNavbar();
    setTextColor({ ...styles.navLink, color: colour });
    setIsBlack(!isBlack);
  };

  const toggleOffCanvas = (e) => {
    setOffCanvasHide(!hideOffCanvas);
  };
  return (
    <>
      <Media
        query="(min-width: 899px) "
        render={() => (
          <>
            <Navbar expand="lg" style={styles.SectionStyle}>
              <Container fluid>
                <Navbar.Brand>
                  <Link to="/" style={{ marginLeft: "20px" }}>
                    <img
                      src={require("../../assets/images/logo1.svg").default}
                      className="d-inline-block align-top"
                      alt="React Bootstrap logo"
                    />
                  </Link>
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="navbarScroll" />
                <Navbar.Collapse id="navbarScroll">
                  <Nav
                    className="me-auto my-2 my-lg-0"
                    style={{ maxHeight: "100px" }}
                    navbarScroll
                  ></Nav>

                  <Form className="d-flex" style={{ marginRight: "20px" }}>
                    <div
                      style={textColor}
                      onClick={handledropDownClick}
                      className="me-5"
                    >
                      Our treatments{" "}
                      <i
                        style={{ fontSize: "22px" }}
                        className="bi bi-caret-down-fill"
                      ></i>
                    </div>
                    <Link style={styles.navLink} to="/about">
                      About us
                    </Link>
                  </Form>
                </Navbar.Collapse>
              </Container>
            </Navbar>
          </>
        )}
      />
      <Media
        query="(max-width: 899px)"
        render={() => (
          <div>
            <Navbar expand={false} style={styles.SectionStyle2}>
              <Container fluid>
                <Navbar.Brand>
                  <Link to="/">
                    <img
                      src={require("../../assets/images/logo1.svg").default}
                      className="d-inline-block align-top"
                      alt="React Bootstrap logo"
                    />
                  </Link>
                </Navbar.Brand>
                <Navbar.Toggle
                  aria-controls="offcanvasNavbar"
                  onClick={toggleOffCanvas}
                />
                <Navbar.Offcanvas
                  show={hideOffCanvas}
                  id="offcanvasNavbar"
                  aria-labelledby="offcanvasNavbarLabel"
                  placement="top"
                  style={{
                    width: "100%",
                    height: "100%",
                    backgroundColor: Colors.woodViewSecondary,
                    color: Colors.woodViewWhite,
                  }}
                >
                  <CloseButton
                    variant="white"
                    style={{
                      display: "block",
                      marginLeft: "auto",
                      marginRight: "20px",
                      marginTop: "20px",
                      fontSize: "5vw",
                    }}
                    onClick={toggleOffCanvas}
                  />
                  <Offcanvas.Header>
                    <Offcanvas.Title id="offcanvasNavbarLabel"></Offcanvas.Title>
                  </Offcanvas.Header>
                  <Offcanvas.Body>
                    <div className="row justify-content-center mt-5 mb-4">
                      <div className="col-auto">
                        <Form.Group controlId="formGridName">
                          <Link
                            to="/"
                            onClick={toggleOffCanvas}
                            style={styles.OffcanvasTextStyle}
                          >
                            <Form.Label>HOME</Form.Label>
                          </Link>
                        </Form.Group>
                      </div>
                    </div>

                    <div className="row justify-content-center mt-5 mb-4">
                      <div className="col-auto">
                        <Form.Group controlId="formGridName">
                          <Link
                            onClick={toggleOffCanvas}
                            to="/sports-massage"
                            style={styles.OffcanvasTextStyle}
                          >
                            <Form.Label>SPORTS MASSAGE</Form.Label>
                          </Link>
                        </Form.Group>
                      </div>
                    </div>

                    <div className="row justify-content-center mt-5 mb-4">
                      <div className="col-auto">
                        <Form.Group controlId="formGridName">
                          <Link
                            onClick={toggleOffCanvas}
                            to="/acupuncture"
                            style={styles.OffcanvasTextStyle}
                          >
                            <Form.Label>ACUPUNCTURE</Form.Label>
                          </Link>
                        </Form.Group>
                      </div>
                    </div>

                    <div className="row justify-content-center mt-5 mb-4">
                      <div className="col-auto">
                        <Form.Group controlId="formGridName">
                          <Link
                            onClick={toggleOffCanvas}
                            to="/hotstone-massage"
                            style={styles.OffcanvasTextStyle}
                          >
                            <Form.Label>HOT STONE MASSAGE</Form.Label>
                          </Link>
                        </Form.Group>
                      </div>
                    </div>

                    <div className="row justify-content-center mt-5 mb-4">
                      <div className="col-auto">
                        <Form.Group controlId="formGridName">
                          <Link
                            onClick={toggleOffCanvas}
                            to="/about"
                            style={styles.OffcanvasTextStyle}
                          >
                            <Form.Label>ALL ABOUT US</Form.Label>
                          </Link>
                        </Form.Group>
                      </div>
                    </div>
                  </Offcanvas.Body>
                </Navbar.Offcanvas>
              </Container>
            </Navbar>
          </div>
        )}
      />
    </>
  );
};

export default TopNavbar;
