import React from "react";
import homeImage1 from "../assets/images/backgroundImages/large/HomePage.png";
import homeImage2 from "../assets/images/backgroundImages/small/HomePage.png";
import BackgroundImage from "../component/BackgroundImage";
import GroupedOfferSection from "../component/offersection/GroupedOfferSection";
import PageDivider from "../component/PageDivider";
import Testimony from "../component/testimony/Testimony";

const Home = (props) => {
  let coverText = "Treatments centered around you & your needs";

  let testimonyText =
    "Nicki got me back to running with regularity…With the treatment and exercises it help to strengthen my core and shoulder… you are a miracle worker.";
  let testimonyName = "Adam Ashenden";

  return (
    <div>
      <BackgroundImage
        image1={homeImage1}
        image2={homeImage2}
        coverText={coverText}
      />
      <PageDivider />
      <GroupedOfferSection />
      <Testimony testimonyText={testimonyText} testimonyName={testimonyName} />
    </div>
  );
};

export default Home;
