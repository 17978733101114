import React from "react";
import FAQContent from "../assets/files/FAQHotStone";
import homeImage1 from "../assets/images/backgroundImages/large/HotStones.png";
import homeImage2 from "../assets/images/backgroundImages/small/HotStones.png";
import timeImage3 from "../assets/images/NinetyMinsIcon.png";
import timeImage from "../assets/images/SixtyMinsIcon.png";
import timeImage2 from "../assets/images/ThirtyMinsIcon.png";
import AccordionCard from "../component/accordion/Accordion";
import BackgroundImage from "../component/BackgroundImage";
import ContentCard from "../component/contentCard/ContentCard";
import PageDivider from "../component/PageDivider";
import Testimony from "../component/testimony/Testimony";

const HotStoneMassage = () => {
  let coverText = "Hot stone massage";
  let mainTitle = "What is hot stone massage?";

  let testimonyText =
    "Nicki is amazing! Attentive, a great listener and communicator, working out tough tension spots in a way I didn’t even, know was possible!";
  let testimonyName = "Phillip Matchett";

  let rightTitle = "Hot stone massage can:";
  let leftContent = [
    {
      content:
        "Hot stone therapy is a specialist massage that uses smooth, flat, heated basalt stones positioned along your spine, in the palms of your hands, along your legs and between the toes. Typically from river beds, basalt stones are used for massage as they have a rich iron content that retains heat.",
    },
  ];
  let rightContent = [
    "boost your circulation",
    "release stored tension",
    "recharge your energy levels",
    "relax you",
    "relieve muscle tension",
  ];

  //out treatments
  //hot stone massage
  // second section
  let title = "Hot stone massage";
  let mainTitle2 = "Our treatments";
  let leftContent2 = [
    {
      title,
      content:
        "A deeply relaxing massage treatment using oils and hot stones. A technique proven to relieve aches & pains and detoxify & heal. A hot stone massage will make you feel nurtured, pampered & special.",
    },
  ];
  let session = "1 hour full body";
  let price = "£60";
  let title2 = "Woodview Ultimate back massage";
  let session2 = "30 minute back and neck";
  let price2 = "£45";
  let rightImageContent = [
    {
      title: title2,
      image: timeImage,
      session,
      price,
    },
    {
      image: timeImage2,
      session: session2,
      price: price2,
    },
  ];

  // third section
  let title3 = "Woodview";
  let subTitle3 = "Ultimate leg massage";
  let leftContent3 = [
    {
      title: title3,
      subTitle: subTitle3,
      content:
        "The ultimate leg massage is a luxury treatment perfect for legs and feet, using deep tissue massage and hot stone therapy. Feel the benefits of an improved range of movement in the leg muscles, reducing any aches and pains followed by profoundly relaxing heat.",
    },
  ];

  let session3 = "1 hour treatment";
  let price3 = "£60";
  let rightImageContent3 = [
    {
      image: timeImage,
      session: session3,
      price: price3,
    },
  ];

  // fourth section
  let title4 = "Woodview";
  let subTitle4 = "Ultimate back massage";
  let leftContent4 = [
    {
      title: title4,
      subTitle: subTitle4,
      content:
        "A 1 hour luxury treatment for back/neck/arms/shoulders using deep tissue massage followed by hot stone therapy. Deep tissue massage to improve shoulder movement and reduce neck pain followed by hot stones to ease tension & stress in the upper body.",
    },
  ];

  let session4 = "1 hour treatment";
  let price4 = "£60";
  let rightImageContent4 = [
    {
      image: timeImage,
      session: session4,
      price: price4,
    },
  ];

  // fifth section
  let title5 = "Woodview";
  let subTitle5 = "Ultimate full body massage";

  let leftContent5 = [
    {
      title: title5,
      subTitle: subTitle5,
      content:
        "The ultimate full-body massage is a 1.5-hour luxury treatment for the whole body, using deep tissue massage and hot stone therapy - the ultimate feel-good treatment. The treatment releases tense muscles all over the body, reducing aches & pains followed by hot stones to aid deep relaxation and a sense of well-being.",
    },
  ];

  let session5 = "1.5 hour treatment";
  let price5 = "£75";
  let rightImageContent5 = [
    {
      image: timeImage3,
      session: session5,
      price: price5,
    },
  ];
  return (
    <div>
      <BackgroundImage
        image1={homeImage1}
        image2={homeImage2}
        coverText={coverText}
      />
      <PageDivider />

      <ContentCard
        mainTitle={mainTitle}
        leftContent={leftContent}
        rightTitle={rightTitle}
        rightContent={rightContent}
      />

      <ContentCard
        mainTitle={mainTitle2}
        leftContent={leftContent2}
        rightImageContent={rightImageContent}
        mobileRes
        first
      />

      <ContentCard
        leftContent={leftContent3}
        rightImageContent={rightImageContent3}
        mobileRes
      />

      <ContentCard
        leftContent={leftContent4}
        rightImageContent={rightImageContent4}
        mobileRes
      />

      <ContentCard
        leftContent={leftContent5}
        rightImageContent={rightImageContent5}
        mobileRes
        last
      />

      <AccordionCard title={"FAQs"} content={FAQContent.FAQHotStone} />
      <Testimony testimonyText={testimonyText} testimonyName={testimonyName} />
    </div>
  );
};

export default HotStoneMassage;
