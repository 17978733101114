import Colors from '../../Colors';

const CardStyle = {
  display: 'block',
  width: '90%',
  marginLeft: 'auto',
  marginRight: 'auto',
  textAlign: 'center',
  marginTop: '10%',
  marginBottom: '10%',
};

const CardStyle2 = {
  display: 'block',
  border: 0,
  padding: '0px',
  marginLeft: 'auto',
  marginRight: 'auto',
  textAlign: 'center',
  marginTop: '5%',
  marginBottom: '5%',
  fontFamily: 'Raleway',
};

const CardStyle2Text = {
  textAlign: 'left',
  marginBottom: '1.85em',
  marginTop: '1.85em',
  fontSize: '1.2em',
  fontFamily: 'Raleway',
};

const CardImage = {
  height: '12rem',
  objectFit: 'cover',
};

const OffersectionTitle = {
  marginTop: '3%',
  color: Colors.woodViewSecondary,
  fontWeight: '700',
  fontSize: '2vh',
};

const HorizontalRuleStyle = {
  width: '100%',
  height: '4px',
  color: Colors.woodViewPrimary,
  margin: 'auto',
  marginTop: '20px',
  marginBottom: '20px',
};

const MobileViewText = {
  fontSize: '20x',
  textAlign: 'center',
};

const ServiceLabel = {
  fontSize: '1.2em',
  textAlign: 'center',
};

const SeeMoreText = {
  color: Colors.woodViewSecondary,
  fontWeight: '700',
};

const OfferImageStyle = {
  width: '100%',
  borderRadius: '4px',
  padding: '5px',
  objectFit: 'cover',
};

const OfferSectionContent = {
  CardStyle: CardStyle,
  CardImage: CardImage,
  CardStyle2: CardStyle2,
  CardStyle2Text: CardStyle2Text,
  OffersectionTitle: OffersectionTitle,
  HorizontalRuleStyle: HorizontalRuleStyle,
  MobileViewText: MobileViewText,
  ServiceLabel: ServiceLabel,
  SeeMoreText: SeeMoreText,
  OfferImageStyle: OfferImageStyle,
};

export default OfferSectionContent;
