import React from 'react';
import OfferSection from './OfferSection';
import item from '../../assets/files/OfferSectionText';
import FlippedOfferSection from './FlippedOfferSection';
import styles from './OfferSectionStyle';

import ContentCardStyles from '../contentCard/ContentCardStyles';

const GroupedOfferSection = () => {
  const promotionalCardItems = item.PromotionalCardItems;

  return (
    <div id='treatment'>
      <div className='container'>
        <div className='row justify-content-around'>
          <div style={styles.OffersectionTitle} className='col-auto'>
            <div style={ContentCardStyles.Title}>What we offer</div>
          </div>
          <div className='col'></div>
        </div>
      </div>
      {promotionalCardItems &&
        promotionalCardItems.map((item, index) => {
          if (index % 2 === 0) {
            return (
              <div key={index}>
                <OfferSection
                  header={item.header}
                  bodyText={item.body}
                  subText={item?.subtext}
                  imgSrc={item?.imgSrc}
                  alt={item.imgAlt}
                  pain={item?.pain}
                  rehab={item?.rehab}
                  wellbeing={item?.wellbeing}
                  seeMore={item?.seeMore}
                  linkTo={item?.linkTo}
                />
              </div>
            );
          } else {
            return (
              <div key={index}>
                <FlippedOfferSection
                  header={item.header}
                  bodyText={item.body}
                  subText={item?.subtext}
                  imgSrc={item?.imgSrc}
                  alt={item.imgAlt}
                  pain={item?.pain}
                  rehab={item?.rehab}
                  wellbeing={item?.wellbeing}
                  seeMore={item?.seeMore}
                  linkTo={item?.linkTo}
                />
              </div>
            );
          }
        })}
    </div>
  );
};

export default GroupedOfferSection;
