export const FAQAboutUs = [
  {
    position: 1,
    question: 'Where do I park?',
    content: [
      'Parking is available on the road directly outside Woodview or the top of Mounts Lane',
    ],
  },

  {
    position: 2,
    question: 'Is there a reception/waiting area?',
    content: ['Sorry no, please wait in your car until your appointment time.'],
  },

  {
    position: 3,
    question: 'Where do I go?',
    content: [
      'Please wait at the blue double doors to the entrance to Woodview and we will meet you there.',
    ],
  },
  {
    position: 4,
    question: 'I’m still not sure of something and can’t find the answer?',
    content: [
      <p>
        Please do not hesitate to call us on <b>07887 553886</b> or email us at{' '}
        <u style={{ color: '#B4436E' }}>nickijames70@gmail.com</u> if you need
        to know anything else
      </p>,
    ],
  },
];

const items = {
  FAQAboutUs: FAQAboutUs,
};

export default items;
