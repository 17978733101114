import React from "react";
import Media from "react-media";
import ContentCardTexts from "./ContentCardTexts";
import ContentCardTextsMobile from "./ContentCardTextsMobile";

const ContentCard = (props) => {
  return (
    <>
      <Media
        query="(min-width: 900px)"
        render={() => <ContentCardTexts {...props} />}
      />
      <Media
        query="(max-width: 899px)"
        render={() =>
          props.mobileRes ? (
            <ContentCardTextsMobile {...props} />
          ) : (
            <ContentCardTexts {...props} />
          )
        }
      />
    </>
  );
};

export default ContentCard;
