import React from "react";
import { Card } from "react-bootstrap";
import { Link } from "react-router-dom";
import pain from "../../../assets/images/backgroundImages/offersection/cardIcons/pain.png";
import rehabilitation from "../../../assets/images/backgroundImages/offersection/cardIcons/rehabilitation.png";
import wellbeing from "../../../assets/images/backgroundImages/offersection/cardIcons/wellbeing.png";
import styles from "../OfferSectionStyle";

const OffersectionMobile = (props) => {
  return (
    <div>
      <Card style={styles.CardStyle}>
        <Card.Img src={props.imgSrc} style={styles.CardImage} />
        <Card.Body>
          {/* //from here */}
          <Card.Title>{props.header}</Card.Title>

          <Card.Text>{props.bodyText}</Card.Text>

          <hr style={styles.HorizontalRuleStyle} />

          <Card.Text>
            Perfect for <b>{props.subText}</b>
          </Card.Text>

          <div className="row">
            {!!props.pain && (
              <div className="col">
                <img
                  src={pain}
                  className="d-inline-block align-top"
                  alt="React Bootstrap logo"
                />
                <div style={styles.MobileViewText}>Pain & injury</div>
              </div>
            )}
            {!!props.rehab && (
              <div className="col">
                <img
                  src={rehabilitation}
                  className="d-inline-block align-top"
                  alt="React Bootstrap logo"
                />
                <div style={styles.MobileViewText}>Rehabilitation</div>
              </div>
            )}
            {!!props.wellbeing && (
              <div className="col">
                <img
                  src={wellbeing}
                  className="d-inline-block align-top"
                  alt="React Bootstrap logo"
                />
                <div style={styles.MobileViewText}>Wellbeing</div>
              </div>
            )}
          </div>

          <hr style={styles.HorizontalRuleStyle} />
          <Card.Text>
            <Link to={props.linkTo} style={styles.SeeMoreText}>
              {props.seeMore}
            </Link>
          </Card.Text>
        </Card.Body>
      </Card>
    </div>
  );
};

export default OffersectionMobile;
