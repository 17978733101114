import Colors from '../../Colors';

const container = {
  padding: '32px',
  backgroundColor: Colors.woodViewPrimaryB,
  borderRadius: '10px',
  fontSize: '1.4em',
  width: '100%',
  marginTop: '5%',
  marginBottom: '10%',
};

const TextGap = {
  marginTop: '1em',
  marginBottom: '1em',
};

const container2 = {
  padding: '32px',
  backgroundColor: Colors.woodViewPrimaryB,
  borderRadius: '10px',
  fontSize: '0.9em',
  width: '95%',
  marginRight: 'auto',
  marginLeft: 'auto',
  marginTop: '5%',
  marginBottom: '10%',
};

const TextGap2 = {
  marginTop: '1em',
  marginBottom: '1em',
  whiteSpace: 'pre-line',
};

const TestimonyStyles = {
  container: container,
  TextGap: TextGap,
  container2: container2,
  TextGap2: TextGap2,
};

export default TestimonyStyles;
