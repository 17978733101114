import React from 'react';
import { Card } from 'react-bootstrap';
import styles from '../OfferSectionStyle';

const OffersectionImageCard = (props) => {
  return (
    <div>
      <Card style={styles.CardStyle2}>
        <img
          src={props.imgSrc}
          className='d-inline-block align-top'
          alt='React Bootstrap logo'
          style={styles.OfferImageStyle}
        />
      </Card>
    </div>
  );
};

export default OffersectionImageCard;
