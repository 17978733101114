import React from "react";
import { Button } from "react-bootstrap";
import Media from "react-media";
import styles from "./PageDividerStyles";
import FullPageModal from "../component/FullPageModal";
import { handleBookingFunction } from "../api/apiCalls";
const PageDivider = () => {
  const [modalShow, setModalShow] = React.useState(false);
  return (
    <>
      <Media
        query="(min-width: 900px)"
        render={() => (
          <div style={styles.SectionStyle}>
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-auto"></div>
                <div className="col-auto ">
                  <div>
                    <Button
                      onClick={() => setModalShow(true)}
                      style={styles.ButtonStyle}
                    >
                      Book a treatment
                    </Button>
                  </div>
                </div>
                <div className="col-auto"></div>
              </div>
              <div className="row justify-content-center">
                <div className="col-auto"></div>
                <div className="col-auto">
                  <div style={styles.TextStyle}>
                    Not sure what you need? We’ll explain eveything you need to
                    know, below.
                  </div>
                </div>
                <div className="col-auto"></div>
              </div>
            </div>
          </div>
        )}
      />
      <Media
        query="(max-width: 899px)"
        render={() => (
          <div style={styles.SectionStyle2}>
            <div className="container">
              <div className="row justify-content-center ">
                <div className="col-auto"></div>
                <div className="col-auto">
                  <div>
                    <Button
                      onClick={() => setModalShow(true)}
                      style={styles.ButtonStyle2}
                    >
                      Book a treatment
                    </Button>
                  </div>
                </div>
                <div className="col-auto"></div>
              </div>
              <div className="row justify-content-center">
                <div className="col-auto"></div>
                <div className="col-auto">
                  <div style={styles.TextStyle2}>
                    Not sure what you need? We’ll explain eveything you need to
                    know, below.
                  </div>
                </div>
                <div className="col-auto"></div>
              </div>
            </div>
          </div>
        )}
      />

      <FullPageModal
        show={modalShow}
        onHide={() => setModalShow(false)}
        functionCall={handleBookingFunction}
      />
    </>
  );
};

export default PageDivider;
