import Colors from '../Colors';

const SectionStyle = {
  height: '20vh',
  backgroundColor: Colors.woodViewSecondary,
  width: '100vw',
  display: 'flex',
  alignItems: 'center',
};

const SectionStyle2 = {
  height: '20vh',
  backgroundColor: Colors.woodViewSecondary,
  width: '100vw',
  display: 'flex',
  alignItems: 'center',
};

const ButtonStyle = {
  backgroundColor: Colors.woodViewWhite,
  color: Colors.woodViewGrey,
  fontSize: '24px',
  padding: '13px',
  paddingLeft: '25px',
  paddingRight: '25px',
  borderRadius: '4px',
};

const ButtonStyle2 = {
  backgroundColor: Colors.woodViewPrimary,
  color: Colors.woodViewGrey,
  fontSize: '2.5vh',
  padding: '1vh',
  paddingLeft: '3vh',
  paddingRight: '3vh',
  borderRadius: '4px',
  borderColor: Colors.woodViewPrimary,
};

const TextStyle = {
  color: Colors.woodViewWhite,
  fontSize: '22px',
  textAlign: 'justify',
  marginTop: '2%',
};

const TextStyle2 = {
  color: Colors.woodViewWhite,
  fontSize: '2.3vh',
  textAlign: 'center',
  marginTop: '3%',
};

const PageDividerStyles = {
  SectionStyle: SectionStyle,
  SectionStyle2: SectionStyle2,
  ButtonStyle: ButtonStyle,
  ButtonStyle2: ButtonStyle2,
  TextStyle: TextStyle,
  TextStyle2: TextStyle2,
};

export default PageDividerStyles;
