import React from "react";
import { Carousel } from "react-bootstrap";
import Media from "react-media";
import TestimonyCard from "./TestimonyCard";
const Testimony = (props) => {
  return (
    <>
      <Media
        query="(min-width: 900px)"
        render={() => (
          <div className="container">
            <div className="row justify-content-around">
              <div className="col">
                <TestimonyCard {...props} />
              </div>
              {/* <div className='col'>
                <TestimonyCard />
              </div> */}
            </div>
          </div>
        )}
      />
      <Media
        query="(max-width: 899px)"
        render={() => (
          <Carousel
            className="carousel slide multi-item-carousel"
            data-ride="carousel"
          >
            <Carousel.Item>
              <TestimonyCard {...props} />
            </Carousel.Item>
            {/* <Carousel.Item>
              <TestimonyCard />
            </Carousel.Item> */}
          </Carousel>
        )}
      />
    </>
  );
};

export default Testimony;
