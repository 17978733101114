import Colors from '../../Colors';

const container = {
  marginTop: '30px',
  width: '50%',
};

const navLink = {
  color: Colors.headerTextColor,
  fontSize: '1.6em',
  textDecoration: 'none',
};

const SectionStyle = {
  height: '12vh',
  backgroundColor: Colors.woodViewPrimary,
  marginBottom: '2vh',
};

const SectionStyle2 = {
  height: '12vh',
  backgroundColor: Colors.woodViewPrimary,
  width: '100vw',
  marginBottom: '2vh',
};

const NavbarBrand = {
  marginLeft: '90px',
  color: 'red',
};

const OffcanvasTextStyle = {
  color: Colors.woodViewWhite,
  fontSize: '1.3em',
};

const NavbarStyles = {
  container: container,
  navLink: navLink,
  SectionStyle: SectionStyle,
  SectionStyle2: SectionStyle2,
  NavbarBrand: NavbarBrand,
  OffcanvasTextStyle: OffcanvasTextStyle,
};

const subNavbarContainer = {
  backgroundColor: Colors.woodViewSecondary,
  color: Colors.woodViewWhite,
  height: '5vh',
  fontSize: '1.5em',
  display: 'flex',
  alignItems: 'center',
  marginBottom: '2vh',
  marginTop: '-2vh',
};

const LinkText = {
  color: Colors.woodViewWhite,
  textDecoration: 'none',
};

export const subNavbarStyles = {
  subNavbarContainer: subNavbarContainer,
  LinkText: LinkText,
};

export default NavbarStyles;
