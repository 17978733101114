import React from "react";
import Media from "react-media";
import Colors from "../Colors";
import "./navbar/Navbar.css";

const BackgroundImage = (props) => {
  var sectionStyle = {
    backgroundImage: `url(${props.image1})`,
    height: "65vh",
    width: "100vw",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
  };

  var sectionStyle2 = {
    backgroundImage: `url(${props.image2})`,
    height: "65vh",
    width: "100vw",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    marginBottom: "12px",
  };

  var overlay = {
    position: "absolute",
    marginTop: "25px",
    background: "rgb(255, 255, 266, 0.5)",
    width: "65%",
    paddingTop: "3vh",
    paddingBottom: "3vh",
    justifyContent: "center",
    opacity: "1",
  };

  let textStyle = {
    textAlign: "left",
    marginLeft: "3vw",
    verticalAlign: "center",
    horizontalAlign: "center !important",
    width: "45%",
    color: Colors.woodViewGrey,
    justifyContent: "center",
    fontSize: "3.4vh",
    fontWeight: "700",
    textTransform: "uppercase",
  };

  var overlay2 = {
    position: "absolute",
    background: "rgb(255, 255, 266, 0.5)",
    width: "100%",
    paddingTop: "20px",
    paddingBottom: "20px",
    opacity: "1",
  };

  let textStyle2 = {
    margin: "auto",
    marginLeft: "20px",
    textAlign: "left",
    color: Colors.woodViewGrey,
    fontSize: "130%",
    fontWeight: "700",
    textTransform: "uppercase",
  };
  return (
    <>
      <Media
        query="(min-width: 900px) "
        render={() => (
          <div style={sectionStyle}>
            {props.coverText && (
              <div style={overlay}>
                <div style={textStyle}>{props.coverText}</div>
              </div>
            )}
          </div>
        )}
      />
      <Media
        query="(max-width: 899px)"
        render={() => (
          <div style={sectionStyle2}>
            {props.coverText && (
              <div style={overlay2}>
                <div style={textStyle2}>{props.coverText}</div>
              </div>
            )}
          </div>
        )}
      />
    </>
  );
};

export default BackgroundImage;
