import Colors from '../../Colors';

const SectionStyle = {
  // height: '20vh',
  backgroundColor: Colors.woodViewSecondary,
  width: '100vw',
  display: 'flex',
  alignItems: 'center',
};

const ButtonStyle = {
  backgroundColor: Colors.woodViewPrimary,
  color: Colors.woodViewGrey,
  fontSize: '1.5em',
  padding: '10px',

  borderRadius: '4px',
  borderColor: Colors.woodViewPrimary,
  width: '100%',
};

const TextInputStyle = {
  backgroundColor: Colors.woodViewWhite,
  color: Colors.woodViewGrey,
  fontSize: '1.5em',
  padding: '15px',
  paddingLeft: '25px',
  paddingRight: '25px',
  borderRadius: '4px',
  borderColor: Colors.woodViewPrimary,
};

const HeaderTextStyle = {
  color: Colors.woodViewWhite,
  fontSize: '24px',
  textAlign: 'justify',
  marginTop: '2%',
};

const SubTextStyle = {
  color: Colors.woodViewWhite,
  fontSize: '18px',
  textAlign: 'justify',
  marginTop: '2%',
};

const ThreeDots = {
  transform: 'rotate(45deg)',
  display: 'inline-block',
  marginRight: '10px',
  marginLeft: '10px',
};
const CheckMark = {
  fontSize: '30px',
};

const PageDividerStyles = {
  SectionStyle: SectionStyle,
  ButtonStyle: ButtonStyle,
  TextInputStyle: TextInputStyle,
  HeaderTextStyle: HeaderTextStyle,
  SubTextStyle: SubTextStyle,
  ThreeDots: ThreeDots,
  CheckMark: CheckMark,
};

export default PageDividerStyles;
