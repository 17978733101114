import React from 'react';
import { Card } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import pain from '../../../assets/images/backgroundImages/offersection/cardIcons/pain.png';
import rehabilitation from '../../../assets/images/backgroundImages/offersection/cardIcons/rehabilitation.png';
import wellbeing from '../../../assets/images/backgroundImages/offersection/cardIcons/wellbeing.png';
import styles from '../OfferSectionStyle';

const OffersectionTextCard = (props) => {
  let bodyStyle = { marginBottom: '3%' };
  return (
    <>
      <Card style={styles.CardStyle2} className='container'>
        <Card.Body style={bodyStyle}>
          {/* //from here */}
          <Card.Title style={styles.CardStyle2Text}>
            <h2>{props.header}</h2>
          </Card.Title>

          <Card.Text style={styles.CardStyle2Text}>{props.bodyText}</Card.Text>

          <hr style={styles.HorizontalRuleStyle} />

          <Card.Text style={styles.CardStyle2Text} className='ms-3'>
            Perfect for <b>{props.subText}</b>
          </Card.Text>

          <div className='row justify-content-start ms-2'>
            {!!props.pain && (
              <div className='col-auto me-5'>
                <img
                  src={pain}
                  className='d-inline-block align-top'
                  alt='React Bootstrap logo'
                />
                <div style={styles.ServiceLabel}>Pain & injury</div>
              </div>
            )}
            {!!props.rehab && (
              <div className='col-auto me-5'>
                <img
                  src={rehabilitation}
                  className='d-inline-block align-top'
                  alt='React Bootstrap logo'
                />
                <div style={styles.ServiceLabel}>Rehabilitation</div>
              </div>
            )}
            {!!props.wellbeing && (
              <div className='col-auto me-5'>
                <img
                  src={wellbeing}
                  className='d-inline-block align-top'
                  alt='React Bootstrap logo'
                />
                <div style={styles.ServiceLabel}>Wellbeing</div>
              </div>
            )}
          </div>

          <hr style={styles.HorizontalRuleStyle} />
        </Card.Body>
        <Card.Footer
          style={{
            backgroundColor: 'white',
            borderTop: 'none',
          }}
        >
          <Card.Text style={styles.CardStyle2Text}>
            <Link to={props.linkTo} style={styles.SeeMoreText}>
              {props.seeMore}
            </Link>
          </Card.Text>
        </Card.Footer>
      </Card>
    </>
  );
};

export default OffersectionTextCard;
