export const FAQHotStone = [
  {
    position: 1,
    question: 'Any medical conditions',
    content: [
      'Tell us about any medical conditions, particularly heart disease or high blood pressure. Also, tell your therapist if you are or suspect you may be pregnant.',
    ],
  },

  {
    position: 2,
    question: 'Stones too warm?',
    content: [
      "Don't be afraid to tell your therapist if the stones are too warm for you. You'll relax quicker and start enjoying that fabulous feeling of total well-being if you're comfortable.",
    ],
  },

  {
    position: 3,
    question: "Don't rush",
    content: [
      "Book hot stone treatments on a day when you're not too busy. You'll feel so relaxed and soothed after your treatment, jumping into the car and tackling the rush hour traffic might seem sacrilegious.",
    ],
  },
];

const items = {
  FAQHotStone: FAQHotStone,
};

export default items;
