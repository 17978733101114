export const FAQAcupuncture = [
  {
    position: 1,
    question: 'How safe is acupuncture?',
    content: [
      'Very safe, we use sterile, single use needles only so there is no risk of infection, and we are highly trained to follow the strictest hygiene standards. Serious side effects are very rare.',
    ],
  },

  {
    position: 2,
    question: 'Does it hurt?',
    content: [
      'The needle sites can produce a slight aching sensation.  Minor bleeding or bruising can occur post treatment.',
    ],
  },

  {
    position: 3,
    question: 'How will I feel after the procedure?',
    content: [
      'Sometimes drowsiness occurs during or after treatment.  If affected, you are advised not to drive. Please ensure you have something to eat and drink a few hours before your treatment.',
    ],
  },

  {
    position: 4,
    question: 'Do I need an initial consultation before treatment?',
    content: [
      'Yes, we will talk about your history and assess your suitability for acupuncture.  You also need to tell us about any medical conditions you may suffer from such as diabetes, heart problems and low blood pressure.',
    ],
  },

  {
    position: 5,
    question: 'How many treatments am I likely to need?',
    content: [
      'Generally, people require about 3 sessions before we can be certain of an effect. The effect of acupuncture is cumulative and pain relief builds up as the treatment progresses. Usually, a course of 6 or more treatments is required for maximum effect.',
    ],
  },
];

const items = {
  FAQAcupuncture: FAQAcupuncture,
};

export default items;
