import React from 'react';
import FAQContent from '../assets/files/FAQAboutUs';
import homeImage1 from '../assets/images/backgroundImages/large/AboutUs.png';
import homeImage2 from '../assets/images/backgroundImages/small/AboutUs.png';
import staLogo from '../assets/images/staLogo.png';
import AccordionCard from '../component/accordion/Accordion';
import AddressDetail from '../component/addressDetail/AddressDetail';
import BackgroundImage from '../component/BackgroundImage';
import ContentCard from '../component/contentCard/ContentCard';
import PageDivider from '../component/PageDivider';

const AboutUs = () => {
  let coverText = 'All about us';

  let mainTitle = 'About us';

  let leftContent = [
    {
      content:
        'Nicki has been a professional Sports Massage Therapist since 2019 and practices from her private treatment room in Newnham near Daventry.  She is trained in Sports Injuries & Remedial Massage, Acupuncture/Dry Needling and Hot Stone Massage.  As an ex-ballet dancer & fitness professional she has spent years working with athletes & understanding their needs.  She offers an experienced pair of hands and works with compassion & commitment to help all her clients.',
    },
    {
      list: [
        'Every treatment is personalised to the individual',
        'Exercise advice to assist in recovery/healing',
        'Quick & efficient results',
        'Continued professional development bringing a wealth of knowledge and experience to each session',
      ],
    },
  ];
  let rightContent = [
    <img
      src={staLogo}
      className='d-inline-block align-top'
      alt='React Bootstrap logo'
    />,
  ];

  const location = {
    address: 'Mounts Ln, Newnham, Daventry, NN11 3ES',
    lat: 52.23299,
    lng: -1.14837,
  }; // our location object from earlier
  return (
    <div>
      <BackgroundImage
        image1={homeImage1}
        image2={homeImage2}
        coverText={coverText}
      />
      <PageDivider />

      <ContentCard
        mainTitle={mainTitle}
        leftContent={leftContent}
        rightContent={rightContent}
        rightIsImage
      />

      <AddressDetail
        mainTitle='Where we are'
        subTitle1='Address:'
        subTitle2='Email:'
        subTitle3='Call:'
        content1='Mounts Ln, Newnham, Daventry 
        Postcode for SatNav directions is NN11 3ES'
        content2='nickijames70@gmail.com'
        content3='07887 553886'
        location={location}
        zoomLevel={15.2}
      />

      <AccordionCard title={'FAQs'} content={FAQContent.FAQAboutUs} />
    </div>
  );
};

export default AboutUs;
