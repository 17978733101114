import React from "react";
import { CuppingSectionText } from "../assets/files/CuppingSectionText";
import FAQContent from "../assets/files/FAQSportsMassage";
import homeImage1 from "../assets/images/backgroundImages/large/SportsMassage.png";
import homeImage2 from "../assets/images/backgroundImages/small/SportsMassage.png";
import timeImage from "../assets/images/SixtyMinsIcon.png";
import AccordionCard from "../component/accordion/Accordion";
import BackgroundImage from "../component/BackgroundImage";
import ContentCard from "../component/contentCard/ContentCard";
import OfferSection from "../component/offersection/OfferSection";
import PageDivider from "../component/PageDivider";
import Testimony from "../component/testimony/Testimony";

const SportsMassage = () => {
  let coverText = "Sports Massage";
  let mainTitle = "What is sports masssage?";
  let leftContent = [
    {
      content:
        "Sports massage involves the manipulation of soft tissue to benefit those engaged in regular physical activity. It assists in correcting problems and imbalances caused, primarily by repetitive and strenuous physical activity and trauma. Before and after exercise, sports massage may enhance performance and recovery while preventing further injury.",
    },
  ];

  let testimonyText =
    "After putting my back out, I couldn’t straighten up. After my 1st visit I was able to straighten up and returned to work the next day. Recommended.";
  let testimonyName = "Sally Parsons";

  let rightTitle = "Helps with conditions like";
  let rightContent = [
    "Muscular strains",
    "Postural issues",
    "Nerve impingements",
    "Frozen shoulder",
    "Sciatica",
    "Carpal tunnel",
    "Tennis/Golfers Elbow",
    "Stiff neck/back/hip pain",
    "Tension headaches​",
  ];

  // second section
  let mainTitle2 = "Our treatment";
  let leftContent2 = [
    {
      title: "Sports Massage",
      content:
        "A 1 hour remedial treatment for either the whole body or a specific area or problem. The massage includes deep tissue massage, trigger point therapy, soft tissue release and stretching/manipulation.",
    },
  ];
  let session = "1 hour full body";
  let price = "£60";
  let rightImageContent = [
    {
      image: timeImage,
      session,
      price,
    },
  ];

  return (
    <div>
      <BackgroundImage
        image1={homeImage1}
        image2={homeImage2}
        coverText={coverText}
      />
      <PageDivider />
      <ContentCard
        mainTitle={mainTitle}
        leftContent={leftContent}
        rightTitle={rightTitle}
        rightContent={rightContent}
      />
      <ContentCard
        mainTitle={mainTitle2}
        leftContent={leftContent2}
        rightImageContent={rightImageContent}
        mobileRes
        first
        last
      />
      {CuppingSectionText &&
        CuppingSectionText.map((item, index) => {
          return (
            <div key={index}>
              <OfferSection
                header={item.header}
                bodyText={item.body}
                subText={item?.subtext}
                imgSrc={item?.imgSrc}
                alt={item.imgAlt}
                pain={item?.pain}
                rehab={item?.rehab}
                wellbeing={item?.wellbeing}
                seeMore={item?.seeMore}
                linkTo={item?.linkTo}
              />
            </div>
          );
        })}
      ;
      <AccordionCard title={"FAQs"} content={FAQContent.FAQSportsMassage} />
      <Testimony testimonyText={testimonyText} testimonyName={testimonyName} />
    </div>
  );
};

export default SportsMassage;
