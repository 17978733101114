import React from "react";
import Media from "react-media";
import Colors from "../../Colors";
import styles from "./FooterStyles";
import { Link } from "react-router-dom";

const Footer = () => {
  let facebookUrl = "https://tinyurl.com/yck6yv7n";
  let instaUrl = "https://tinyurl.com/2uxet7ya";

  return (
    <>
      <Media
        query="(min-width: 900px)"
        render={() => (
          <div>
            <footer className="text-left text-lg-left">
              <div className="container pt-5 pb-5 ">
                <div className="row ">
                  <div className="col-lg-1 col-6 col-md-6 mb-4 mb-md-0">
                    <img
                      src={require("../../assets/images/logo2.svg").default}
                      className="d-inline-block align-top"
                      alt="React Bootstrap logo"
                    />
                  </div>

                  <div className="col-lg-4 col-6 col-md-6 mb-4 mb-md-0">
                    <h5 className="mb-3" style={styles.HeaderText}>
                      Contact
                    </h5>

                    <ul className="list-unstyled">
                      <li className="mb-3 ">07887 553886</li>
                      <li>Mounts Ln, Newnham, Daventry NN11 3ES</li>
                    </ul>
                  </div>

                  <div className="col-lg-2 col-6 col-md-6 mb-4 mb-md-0">
                    <h5 className="mb-3" style={styles.HeaderText}>
                      Legal
                    </h5>

                    <ul className="list-unstyled ">
                      <li>Privacy</li>
                    </ul>
                  </div>

                  <div className="col-lg-2 col-6 col-md-6 mb-4 mb-md-0">
                    <h5 className=" mb-3" style={styles.HeaderText}>
                      Company
                    </h5>

                    <ul className="list-unstyled">
                      <Link
                        to="/about"
                        style={{ color: "inherit", textDecoration: "none" }}
                      >
                        About us
                      </Link>
                    </ul>
                  </div>

                  <div className="col-lg-2 col-6 col-md-6 mb-4 mb-md-0">
                    <ul className="list-unstyled list-inline">
                      <li
                        style={{ fontSize: "2em", cursor: "pointer" }}
                        className="list-inline-item "
                        onClick={() => window.open(facebookUrl, "_blank")}
                      >
                        <i className="bi bi-facebook "></i>
                      </li>

                      <li
                        style={{ fontSize: "2em", cursor: "pointer" }}
                        className="list-inline-item ms-4"
                        onClick={() => window.open(instaUrl, "_blank")}
                      >
                        <i className="bi bi-instagram"></i>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>

              <div className="text-center p-3">
                <p style={{ color: Colors.wireFrameShame3 }}>
                  © Woodview body & therapy. All rights reserved
                </p>
              </div>
            </footer>
          </div>
        )}
      />
      <Media
        query="(max-width: 899px)"
        render={() => (
          <div>
            <footer className="text-left text-lg-left">
              <div className="container pt-5 pb-5 ">
                <div className="row ">
                  <div className="col-lg-1 col-6 col-md-6 mb-4 mb-md-0">
                    <img
                      src={require("../../assets/images/logo2.svg").default}
                      className="d-inline-block align-top"
                      alt="React Bootstrap logo"
                    />
                  </div>

                  <div className="col-lg-1 col-6 col-md-6 mb-4 mb-md-0"></div>

                  <div className="h-100 d-inline-blockcol-lg-4 col-6 col-md-6">
                    <h5 className="mb-3" style={styles.HeaderText}>
                      Contact
                    </h5>

                    <ul className="list-unstyled">
                      <li className="mb-0">07887 553886</li>
                    </ul>
                  </div>

                  <div className="col-lg-2 col-6 col-md-6">
                    <h5 className=" mb-3" style={styles.HeaderText}>
                      Company
                    </h5>
                    <ul className="list-unstyled list-inline">
                      <li
                        style={{ cursor: "pointer" }}
                        className="list-inline-item "
                        onClick={() => window.open("/about", "_self")}
                      >
                        About us
                      </li>
                    </ul>{" "}
                  </div>

                  <div
                    className="col-lg-4 col-6 col-md-6"
                    style={{ marginBottom: "0px" }}
                  >
                    <ul className="list-unstyled" style={{ marginBottom: "0" }}>
                      <li className="mb-0">Mounts Ln,</li>
                      <li style={{ marginBottom: "0px" }} className="mb-0">
                        Newnham,
                      </li>
                    </ul>
                  </div>

                  <div className="col-lg-2 col-6 col-md-6 ">
                    <h5 className="mb-3" style={styles.HeaderText}>
                      Legal
                    </h5>

                    <ul className="list-unstyled ">
                      <li>Privacy</li>
                    </ul>
                  </div>

                  <div
                    className="col-lg-4 col-6 col-md-6"
                    style={{ marginBottom: "0px", marginTop: "0px" }}
                  >
                    <ul
                      className="list-unstyled"
                      style={{ marginBottom: "0px", marginTop: "-10px" }}
                    >
                      <li>Daventry</li>
                      <li> NN11 3ES</li>
                    </ul>
                  </div>

                  <div className="col-lg-2 col-6 col-md-6">
                    <ul className="list-unstyled list-inline">
                      <li
                        style={{ fontSize: "2em", cursor: "pointer" }}
                        className="list-inline-item "
                        onClick={() => window.open(facebookUrl, "_blank")}
                      >
                        <i className="bi bi-facebook "></i>
                      </li>

                      <li
                        style={{ fontSize: "2em", cursor: "pointer" }}
                        className="list-inline-item ms-4"
                        onClick={() => window.open(instaUrl, "_blank")}
                      >
                        <i className="bi bi-instagram"></i>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>

              <div className="text-center p-3">
                <p style={{ color: Colors.wireFrameShame3 }}>
                  © Woodview body & therapy. All rights reserved
                </p>
              </div>
            </footer>
          </div>
        )}
      />
    </>
  );
};

export default Footer;
