import cupping from '../images/backgroundImages/offersection/CuppingImage.png';

export const CuppingSectionText = [
  {
    position: 1,
    imgSrc: cupping,
    imgAlt: 'A customer having cupping procedure',
    header: 'Cupping',
    body: 'We also offer dry cupping as an alternative treatment method',
    subtext: 'everyone',
    pain: true,
    rehab: true,
    wellbeing: true,
  },
];

const items = {
  CuppingSectionText: CuppingSectionText,
};

export default items;
