import Colors from '../../Colors';

const Title = {
  marginTop: '3%',
  color: Colors.woodViewSecondary,
  fontSize: '1.83em',
  fontWeight: '700',
};

const NewSection = {
  marginTop: '3%',
};
const NewSection2 = {
  marginTop: '3%',
  marginBottom: '3%',
  textAlign: 'center',
};
const PriceTextAlign = {
  marginTop: 'auto',
  marginBottom: 'auto',
};

const subTitle = {
  color: Colors.woodViewSecondary,
  fontSize: '25px',
  fontWeight: '700',
};

const HorizontalRuleStyle = {
  width: '80%',
  height: '3px',
  color: Colors.woodViewPrimary,
  margin: 'auto',
};
const HorizontalRuleStyle2 = {
  width: '100%',
  height: '3px',
  color: Colors.woodViewPrimary,
};

const styles = {
  Title: Title,
  subTitle: subTitle,
  NewSection: NewSection,
  NewSection2: NewSection2,
  PriceTextAlign: PriceTextAlign,
  HorizontalRuleStyle: HorizontalRuleStyle,
  HorizontalRuleStyle2: HorizontalRuleStyle2,
};

export default styles;
