import React from "react";
import Media from "react-media";
import { Link } from "react-router-dom";
import { subNavbarStyles } from "./NavbarStyles";

const SubNavbar = (props) => {
  return (
    <>
      {props.dropdownVisible === true ? (
        <>
          <Media
            query="(min-width: 900px) "
            render={() => (
              <div style={subNavbarStyles.subNavbarContainer}>
                <div className="container">
                  <div className="row justify-content-between">
                    <Link
                      style={subNavbarStyles.LinkText}
                      to="/"
                      className="col-auto"
                    >
                      Home
                    </Link>
                    <Link
                      style={subNavbarStyles.LinkText}
                      to="/sports-massage"
                      className="col-auto"
                    >
                      Sports Massage
                    </Link>
                    <Link
                      style={subNavbarStyles.LinkText}
                      to="/acupuncture"
                      className="col-auto"
                    >
                      Acupuncture
                    </Link>
                    <Link
                      style={subNavbarStyles.LinkText}
                      to="/hotstone-massage"
                      className="col-auto"
                    >
                      Hot Stone Massage
                    </Link>
                  </div>
                </div>
              </div>
            )}
          />
        </>
      ) : null}
    </>
  );
};

export default SubNavbar;
