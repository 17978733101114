import React from "react";
import Media from "react-media";
import logo from "../../assets/images/quoteIcon.png";
import styles from "./TestimonyStyles";

const TestimonyCard = (props) => {
  return (
    <>
      <Media
        query="(min-width: 900px)"
        render={() => (
          <div className="container" style={styles.container}>
            <div
              className="row justify-content-around"
              style={{ display: "inline" }}
            >
              <div className="col-12">
                <img
                  src={logo}
                  className="d-inline-block align-top"
                  alt="React Bootstrap logo"
                />
              </div>
            </div>

            <div className="row justify-content-around">
              <div className="col-12">{props.testimonyText}</div>
            </div>
            <div className="row justify-content-around" style={styles.TextGap}>
              <div className="col-12">{props.testimonyName}</div>
            </div>
          </div>
        )}
      />
      <Media
        query="(max-width: 899px)"
        render={() => (
          <div className="container" style={styles.container2}>
            <div className="row justify-content-around">
              <div className="col-12" style={styles.TextGap2}>
                <img
                  src={logo}
                  className="d-inline-block align-top"
                  alt="React Bootstrap logo"
                />
              </div>
            </div>

            <div className="row justify-content-around">
              <div className="col-12">{props.testimonyText}</div>
            </div>
            <div className="row justify-content-around" style={styles.TextGap}>
              <div className="col-12">{props.testimonyName}</div>
            </div>
          </div>
        )}
      />
    </>
  );
};

export default TestimonyCard;
